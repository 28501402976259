import { Controller } from '@hotwired/stimulus'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
dayjs.locale('zh-cn')
// var localeData = require('dayjs/plugin/localeData')
// dayjs.extend(localeData)

// import weekday from 'dayjs/plugin/weekday'
// dayjs.extend(weekday)

export default class extends Controller {
  static targets = [
    'money',
    'valueDate',
    'rate',
    // 'returnRate',
    'cashback',
    'payMoney',
    'payAt'
  ]
  static values = {
    // 两次付款日期间隔，默认30天
    diff: Number,
    // 产品周期 月数
    months: Number,
    payAtWay: String,
    diffDays: Number
  }
  connect() {
    // const globalLocaleData = dayjs.localeData()

    console.log(
      'payments list',
      // this.moneyTarget,
      // this.payMoneyTargets,
      // this.payAtTargets,
      dayjs.locale()
      // globalLocaleData.firstDayOfWeek(),
      // dayjs().weekday(0).format('YYYY-MM-DD').toString(),
      // dayjs().weekday(7).format('YYYY-MM-DD').toString(),
      // dayjs(new Date('2022-05-20')).weekday(4).format('YYYY-MM-DD').toString()
    )
  }
  // 金额更改时生成兑付利息
  changeMoney() {
    // console.log(
    //   this.money,
    //   this.rate,
    //   'money',
    //   (this.money * this.rate) / this.timesValue
    // )
    this.renderPayMoney()
    // this.renderCashback()
  }

  renderCashback() {
    const cash = (this.money * this.returnRate * this.monthsValue) / 12
    this.cashbackTarget.value = cash.toFixed(0)
  }

  renderPayMoney() {
    const size = this.payMoneyTargets.length || 1
    console.log(
      (this.money * this.rate * this.monthsValue) / 12,
      'money---',
      this.money,
      this.rate,
      this.monthsValue
    )
    this.payMoneyTargets.forEach(target => {
      const value = (
        (this.money * this.rate * this.monthsValue) /
        12 /
        size
      ).toFixed(0)
      if (target.value === '' || target.value) target.value = value
    })
  }

  renderPayAt() {
    // const size = this.payAtTargets.length || 1
    const valueDate = this.valueDateTarget.value
    this.payAtTargets.forEach((target, index) => {
      if (target.value || target.value == '')
        target.value = dayjs(valueDate)
          .add(this.diffValue * (index + 1), 'month')
          .format('YYYY-MM-DD')
          .toString()
    })
  }

  // 实际起息日更改时生成兑付日
  changeValueDate() {
    this.renderPayAt()
  }

  // 开始计算日期
  changePayAt(e) {
    console.log(e.currentTarget.value, this.payAtWayValue)
    const value = e.currentTarget.value
    if (this.payAtWayValue === 'week') {
      this.changeByWeek(value)
    } else {
      this.changeByDay(value)
    }
    // this.valueDateTarget.value = dayjs(e.currentTarget.value)
    //   .add(this.diffDaysValue, 'day')
    //   .format('YYYY-MM-DD')
    //   .toString()
    this.changeValueDate()
  }

  changeByDay(date) {
    this.valueDateTarget.value = dayjs(date)
      .add(this.diffDaysValue, 'day')
      .format('YYYY-MM-DD')
      .toString()
  }

  changeByWeek(date) {
    // dayjs().isoWeekday(1).format('YYYY-MM-DD').toString()

    // const valueDate = dayjs(date)
    //   .isoWeekday(this.diffDaysValue)
    //   .format('YYYY-MM-DD')
    //   .toString()
    const valueDate = dayjs(this.nextDay(date, this.diffDaysValue))
      .format('YYYY-MM-DD')
      .toString()
    console.log(valueDate, this.diffDaysValue, date)
    this.valueDateTarget.value = valueDate
  }

  nextDay(dateStr, x) {
    var date = new Date(dateStr)
    date.setDate(date.getDate() + ((x + (7 - date.getDay())) % 7))
    return date
  }

  get money() {
    return this.moneyTarget.value * 10000 || 0
  }
  get rate() {
    return this.rateTarget.value / 100 || 0
  }
  get returnRate() {
    return this.returnRateTarget.value / 100 || 0
  }
}
