const lang = {
  attachFiles: '上传文件',
  bold: '加粗',
  bullets: '无序列表',
  byte: 'Byte',
  bytes: 'Bytes',
  captionPlaceholder: '添加描述…',
  code: '代码',
  heading1: '标题',
  indent: '增加缩进',
  italic: '斜体',
  link: '链接',
  numbers: '有序列表',
  outdent: '减少缩进',
  quote: '引用',
  redo: '重做',
  remove: '删除',
  strike: '删除线',
  undo: '撤销',
  unlink: '移除',
  url: 'URL地址',
  urlPlaceholder: '输入链接地址…',
  GB: 'GB',
  KB: 'KB',
  MB: 'MB',
  PB: 'PB',
  TB: 'TB'
}
document.addEventListener('trix-before-initialize', function () {
  console.log('trix-before-initialize')
  Object.assign(Trix.config.lang, lang)
})
