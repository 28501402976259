// import { Controller } from '@hotwired/stimulus'
import { Autocomplete } from 'stimulus-autocomplete'

export default class extends Autocomplete {
  connect() {
    console.log('autocomplete')
    super.connect()
    document.addEventListener(
      'autocomplete.change',
      this.autocomplete.bind(this)
    )
  }
  autocomplete(event) {
    const textValue = event.detail.textValue
    // console.log(JSON.parse(textValue))
    this.renderInputs(JSON.parse(textValue))
  }
  renderInputs(user) {
    const cols = [
      'name',
      'mobile',
      'bank_name',
      'card_number',
      'contact_name',
      'contact_mobile'
    ]
    cols.forEach(key => {
      const el = document.getElementById('ticket_' + key)
      if (el) {
        el.value = user[key]
      }
    })
    console.log(user)
  }
}
