export const circleProgress = (
  text = 0,
  currentLong = 0,
  totalLong = 295.161
) => {
  const html = `<div class="relative w-full h-full upload-progress">
  <svg viewBox="0 0 100 100">
    <path d="
        M 50 50
        m 0 -47
        a 47 47 0 1 1 0 94
        a 47 47 0 1 1 0 -94
        " stroke-width="4.8" fill="none" class="text-gray-300 stroke-current" >
    </path>
    <path d="
        M 50 50
        m 0 -47
        a 47 47 0 1 1 0 94
        a 47 47 0 1 1 0 -94
        "  class="text-blue-500 stroke-current upload-progress__circle" fill="none" stroke-linecap="round" stroke-width="4.8" style="stroke-dasharray: ${currentLong}, ${totalLong};">
    </path>
  </svg>
  <div class="absolute left-0 w-full text-center top-1/2 upload-progress__text" style="transform: translateY(-50%)">
    ${text}
  </div>
  </div>`
  return html
}
