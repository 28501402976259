import { Dropdown } from 'tailwindcss-stimulus-components'
export default class ButtonDropdown extends Dropdown {
  // connect() {
  //   super.connect()
  //   console.log(this.openValue, this.menuTarget.classList)
  // }
  teardown() {
    this.menuTarget.classList.add('hidden')
    this.openValue = false
  }
}
