import { Controller } from '@hotwired/stimulus'
// import Rails from '@rails/ujs'
// import { Modal } from 'bootstrap'
import TomSelect from 'tom-select'

let myModal = null

export default class extends Controller {
  static targets = ['userField']
  connect() {
    if (this.hasUserFieldTarget) {
      console.log('this.hasUserFieldTarget')
      this.enableTS()
    }
  }

  disconnect() {
    // const myModalEl = document.getElementById('customer-modal')
    // myModal.hide()
    // const customerForm = document.getElementById('new_user')
    // myModalEl.addEventListener('hidden.bs.modal', function (event) {
    //   customerForm.reset();
    //   Rails.enableElement(customerForm);
    // });
  }

  initUserInfo(user) {
    const cols = ['name', 'mobile', 'id_number', 'bank_name', 'card_number']
    cols.forEach(key => {
      const el = document.getElementById('ticket_' + key)
      if (el) {
        el.value = user[key]
      }
    })
  }

  eventHandler(name) {
    const self = this
    return function (value) {
      console.log('name, value')
      self.fetchUser(value)
    }
  }

  // fetch user by id
  fetchUser(id) {
    const self = this
    fetch('/ucenter/users/' + id)
      .then(res => res.json())
      .then(res => {
        console.log(res)
        self.initUserInfo(res)
      })
  }

  enableTS() {
    // console.log('enable', this.userFieldTarget)
    new TomSelect(this.userFieldTarget, {
      valueField: 'id',
      labelField: 'title',
      searchField: 'title',
      // fetch remote data
      load: function (query, callback) {
        var url = '/ucenter/users?q=' + encodeURIComponent(query)
        fetch(url)
          .then(response => response.json())
          .then(json => {
            callback(json)
          })
          .catch(() => {
            callback()
          })
      },
      // render: {
      // option: function (data, escape) {
      //   console.log(data, 'Data====')
      //   return `<div> <span class="name">${escape(
      //     data.name
      //   )}</span> --- <span class="mobile">${escape(
      //     data.mobile
      //   )}</span> </div>`
      // },
      // item: function (data, escape) {
      //   return `<div class=""><span class="name">${escape(
      //     data.name
      //   )}</span> --- <span class="mobile">${escape(data.mobile)}<div>`
      // }

      // },
      render: {
        option_create: function (data, escape) {
          return (
            '<div class="create">新建 <strong>' +
            escape(data.input) +
            '</strong>&hellip;</div>'
          )
        },
        no_results: function (data, escape) {
          return '<div class="no-results">没有找到结果</div>'
        }
      },
      create: function (input, _callback) {
        const btn = document.getElementById('new-user')
        btn.click()
        setTimeout(() => {
          const mobile = document.getElementById('user_mobile')
          mobile.value = input
        }, 200)
      },
      onItemAdd: this.eventHandler('onChange')
      // onLoad: this.eventHandler('onLoad'),
      // onOptionAdd: e => {
      //   console.log('on option add', e)
      // }
      // onItemAdd: this.eventHandler('on add')
      // onItemAdd: e => {
      //   console.log('init', e)
      // }
    })
  }
}
