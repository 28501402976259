import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'
export default class extends Controller {
  static values = { url: String }

  connect() {
    console.log(this.element, this.urlValue)
    this.renderSelect()
  }

  renderSelect() {
    const self = this
    new TomSelect(this.element, {
      plugins: ['remove_button'],
      valueField: 'name',
      labelField: 'name',
      searchField: 'name',
      persist: false,
      createOnBlur: true,
      create: true,

      render: {
        option_create: function (data, escape) {
          return (
            '<div class="create">添加 <strong>' +
            escape(data.input) +
            '</strong>&hellip;</div>'
          )
        },
        no_results: function (data, escape) {
          return '<div class="no-results">没有找到结果</div>'
        },
        item: function (item, escape) {
          return `<div style="margin-bottom: 0;">${escape(item.name)}</div>`
        }
      },
      onItemAdd: function () {
        this.setTextboxValue('')
        this.refreshOptions()
      }

      // fetch remote data
      // load: function (query, callback) {
      //   var url = `${self.urlValue}?q=${encodeURIComponent(query)}`
      //   fetch(url)
      //     .then(response => response.json())
      //     .then(json => {
      //       callback(json.items)
      //     })
      //     .catch(() => {
      //       callback()
      //     })
      // }
    })
  }

  disconnect() {}
}
