import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

export default class extends Controller {
  static targets = ['money']
  static values = {
    rates: Array
  }

  connect() {
    console.log(this.moneyTarget, this.ratesValue)
  }

  renderRate(item) {
    console.log(item, 'items')
    const cols = ['rate', 'commision_rate']
    cols.forEach(col => {
      const el = document.getElementById('ticket_' + col)
      el.value = item[col]
    })
  }

  loadRate(value) {
    const rates = this.ratesValue
    console.log(rates, 'rates', value)
    const item = rates.find(rate => {
      return (
        value >= parseInt(rate.min_money) && value <= parseInt(rate.max_money)
      )
    })

    if (item) {
      this.renderRate(item)
    }
  }

  change(e) {
    console.log(e.target.value, 'e')
    this.loadRate(parseInt(e.target.value))
  }

  enableTs() {
    new TomSelect(this.moneyTarget, {})
  }
}
