import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'overlay']
  connect() {}
  toggle() {
    console.log('toggle', this.formTarget)

    if (this.formTarget.classList.contains('translate-x-80')) {
      this.formTarget.classList.remove('translate-x-80')
      this.overlayTarget.classList.remove('hidden')
    } else {
      this.formTarget.classList.add('translate-x-80')
      this.overlayTarget.classList.add('hidden')
    }
  }

  clear(e) {
    e.preventDefault()
    this.clearFiler()
  }

  clearFiler() {
    const params = window.location.search.slice(1).split('&')
    const regex = /^(q\[|q%5B|q%5b|page|commit)/
    var param

    const search = (function () {
      var i, len, results
      results = []
      for (i = 0, len = params.length; i < len; i++) {
        param = params[i]
        if (!param.match(regex)) {
          results.push(param)
        }
      }
      return results
    })().join('&')
    Turbo.visit(window.location.pathname + '?' + search)
  }

  disconnect() {}
}
