import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static values = { url: String }
  connect() {
    setTimeout(() => {
      console.log('redirecting', this.urlValue)
      Turbo.visit(this.urlValue, { action: 'replace' })
    }, 1000)
  }
}
