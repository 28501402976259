import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['links', 'template', 'items']

  connect() {
    this.wrapperClass = this.data.get('wrapperClass') || 'nested-fields'
    console.log(this.wrapperClass)
    this.renderIndex()
  }

  add_association(event) {
    event.preventDefault()

    var content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      this.itemLength
    )
    console.log(this.itemLength, 'items----')
    this.itemsTarget.insertAdjacentHTML('beforeend', content)
    this.renderIndex()
  }

  remove_association(event) {
    event.preventDefault()

    let wrapper = event.target.closest('.' + this.wrapperClass)

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == 'true') {
      wrapper.remove()

      // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
      wrapper.classList.remove('nested-fields')
    }
    this.renderIndex()
  }
  get itemLength() {
    return this.itemsTarget.querySelectorAll('.' + this.wrapperClass).length
  }

  get items() {
    return this.itemsTarget.querySelectorAll('.' + this.wrapperClass)
  }

  renderIndex() {
    // add index num
    this.items.forEach((item, index) => {
      const num = item.querySelector('.item-num')
      if (num) {
        num.innerHTML = index + 1
      }
    })
  }
}
